<template>
  <c-grid
    :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
    gap="3"
  >
    <c-box
      v-for="index in 6"
      :key="index"
      w="100%"
    >
      <c-flex
        flex-direction="row"
        w="100%"
        background-color="white"
        border-radius="12px"
        border="1px solid #f2f2f2"
        box-shadow="0px 5px 30px 0px #0000000D"
        px="16px"
        py="16px"
        pos="relative"
        margi-bottom="16px"
        align="center"
      >
        <c-box
          d="block"
          w="64px"
          h="64px"
          border-radius="100%"
          mr="1rem"
        >
          <vue-skeleton-loader
            type="circle"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>
        <c-flex
          flex-grow="1"
        >
          <c-flex
            flex-direction="column"
            w="100%"
          >
            <c-box
              d="block"
              w="170px"
              h="24px"
              mb="10px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-box
              d="block"
              w="100px"
              h="18px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
          </c-flex>
          <c-box
            d="block"
            w="80px"
            h="20px"
            mb="0"
            align-self="center"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              rounded
              animation="fade"
            />
          </c-box>
        </c-flex>
      </c-flex>
    </c-box>
  </c-grid>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'SkeletonCardClient',
  components: {
    VueSkeletonLoader,
  },
}
</script>
