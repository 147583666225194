<template>
  <c-box 
    w="100%" 
    margin-bottom="0" 
    as="router-link" 
    :to="`/admin/clients/${item.id}`"
  >
    <c-flex
      w="100%"
      background-color="white"
      border-radius="12px"
      border="1px solid #f2f2f2"
      box-shadow="0px 5px 30px 0px #0000000D"
      px="1rem"
      py="1rem"
      align="center"
    >
      <c-box
        min-w="64px"
        w="64px"
        h="64px"
        margin-right="1rem"
        overflow="hidden"
        border-radius="100%"
        background-color="lightGray.900"
      >
        <c-image
          :src="getPhotoUser(item.photoUrl)"
          :alt="item.firstName"
        />
      </c-box>
      <c-flex
        flex-grow="1"
        justify="space-between"
      >
        <c-flex
          flex-direction="column"
          justify="space-between"
        >
          <c-heading
            as="h3"
            font-size="18px"
            line-height="20px"
            font-weight="700"
            color="black.900"
            flex-grow="1"
            margin-bottom="2px"
            class="line-clamp-2"
          >
            {{ item.firstName }} {{ item.lastName }}
          </c-heading>
          <c-text
            v-if="item.programs"
            font-family="Roboto"
            font-size="14px"
            line-height="21px"
            color="primary.400"
            margin-bottom="0"
          >
            {{ item.programs.name }} 
            <!-- ({{ item.programs.programService }}) -->
          </c-text>
        </c-flex>
        <c-flex>
          <c-box
            as="span"
            :color="item.programs.status === 'pending'
              ? '#DA6D06'
              : item.programs.status == 'done'
                ? '#008C81'
                : item.programs.status == 'active'
                  ? '#0C72E0'
                  : '#F2F2F2'"
            font-size="14px"
            font-weight="500"
            display="inline-block"
            vertical-align="middle"
            margin-left="8px"
            align-self="center"
            text-transform="capitalize"
            class="line-clamp-2"
          >
            {{ item.programs.status | programStatus }}
          </c-box>
          <c-icon
            name="chevron-right"
            size="24px"
            color="#008C81"
            align-self="center"
          />
        </c-flex>
      </c-flex>
    </c-flex>
  </c-box>
</template>
<script>
import generalMixin from '@/utils/general-mixins'
export default {
  name: 'ManagementCardClient',
  filters: {
    programStatus(value) {
      if (value === 'active') {
        return 'Aktif'
      } else if (value === 'pending') {
        return 'Pending'
      } else if (value === 'done') {
        return 'Selesai'
      }
    },
  },
  mixins: [generalMixin],
  props: ['item'], 
}
</script>