var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "margin-bottom": "0",
      "as": "router-link",
      "to": "/admin/clients/".concat(_vm.item.id)
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "background-color": "white",
      "border-radius": "12px",
      "border": "1px solid #f2f2f2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "px": "1rem",
      "py": "1rem",
      "align": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "min-w": "64px",
      "w": "64px",
      "h": "64px",
      "margin-right": "1rem",
      "overflow": "hidden",
      "border-radius": "100%",
      "background-color": "lightGray.900"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.getPhotoUser(_vm.item.photoUrl),
      "alt": _vm.item.firstName
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "justify": "space-between"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify": "space-between"
    }
  }, [_c('c-heading', {
    staticClass: "line-clamp-2",
    attrs: {
      "as": "h3",
      "font-size": "18px",
      "line-height": "20px",
      "font-weight": "700",
      "color": "black.900",
      "flex-grow": "1",
      "margin-bottom": "2px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.firstName) + " " + _vm._s(_vm.item.lastName) + " ")]), _vm.item.programs ? _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": "14px",
      "line-height": "21px",
      "color": "primary.400",
      "margin-bottom": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.programs.name) + " ")]) : _vm._e()], 1), _c('c-flex', [_c('c-box', {
    staticClass: "line-clamp-2",
    attrs: {
      "as": "span",
      "color": _vm.item.programs.status === 'pending' ? '#DA6D06' : _vm.item.programs.status == 'done' ? '#008C81' : _vm.item.programs.status == 'active' ? '#0C72E0' : '#F2F2F2',
      "font-size": "14px",
      "font-weight": "500",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-left": "8px",
      "align-self": "center",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("programStatus")(_vm.item.programs.status)) + " ")]), _c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px",
      "color": "#008C81",
      "align-self": "center"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }